<template lang="pug">
	#facebook-pixels-root
		#facebook-pixels-main
			.text-left
				h5 Facebook Pixel Settings
				p Here you can manage the Facebook Pixel settings, change the pixel ID, and enable/disable the pixel, change the access token etc. You can also add a new pixel entry by clicking the "Add" button.
				p Please, make sure that the pixel is set to the relevant category as it affects the application through which the requests will be sent to the FB API. If the category is set, the application will map it in favor of the main category.&nbsp;
					| If the lead comes with an encrypted string that includes the category, it will favor it instead of both the main and the mapped one.

				label.label-text-gray Entries
				el-tooltip(content="Manually add an category to the list")
					el-button.ml-3(type="info" icon="el-icon-plus" @click="setCreate" size="mini") Add

			el-form
				el-row.mt-2(:gutter="12")
					el-col(:xs="12" :sm="6")
						el-form-item(label="Search...")
							el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

					el-col(:xs="12" :sm="6")
						el-form-item(label="In Use")
							el-select.w-100(v-model="filters.in_use" @change="fetchData")
								el-option(label="All" value="" )
								el-option(label="Yes" value="1")
								el-option(label="No" value="0" )
					el-col(:xs="12" :sm="6")
						el-form-item(label="Owners")
							el-select.w-100(filterable="" v-model="filters.owner" @change="fetchData")
								el-option(label="- None, Select an owner -" :value="''")
								el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")

					el-col(:xs="12" :sm="6")
						el-form-item(label="Categories")
							el-select.w-100(filterable="" v-model="filters.category" @change="fetchData")
								el-option(label="- None, Select a category -" :value="''")
								el-option(v-for="item in categories" :key="item.key" :label="item.label" :value="item.key")
			el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' )
				el-table-column(align='center' label="ID" prop="ext_id" width="130")
					template(slot-scope='scope')
						span {{ scope.row.ext_id }}
				el-table-column(align='center' label="Name" prop="name")
					template(slot-scope='scope')
						span {{ scope.row.name }}
				el-table-column(align='center' label="Owner" prop="owner" )
					template(slot-scope='scope')
						el-tooltip(v-if="scope.row.user_id")
							template(slot="content")
								span.d-block <b>Name: </b> {{scope.row.owner.first_name}} {{scope.row.owner.last_name}}
								span.d-block <b>Email: </b>{{scope.row.owner.email}}
								span.d-block <b>Username: </b>{{scope.row.owner.username}}
							span.chooseable {{scope.row.owner.username}}
						span.text-info(v-else="")
							i No Owner
				el-table-column(align='center' label="In Use" prop="in_use" width="100")
					template(slot-scope='scope')
						font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!scope.row.in_use", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")

				el-table-column(align='center' label="Use Own Token" prop="in_use" width="100")
					template(slot-scope='scope')
						font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!scope.row.use_own_token", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")

				el-table-column(align='center' label="Category" prop="category" width="100")
					template(slot-scope='scope')
						el-tooltip(v-if="scope.row.user_id")
							template(slot="content")
								span.d-block <b>Name: </b> {{scope.row.category.name}}
								span.d-block <b>Notes: </b>{{scope.row.category.notes}}
								span.d-block <b>UUID: </b>{{scope.row.category.uuid}}
							span.chooseable {{scope.row.category.name}}
						span.text-info(v-else="")
							i No Owner
				el-table-column(align='center' label="Comments" prop="comments")
					template(slot-scope='scope')
						span(v-if="scope.row.comments.length <= 50") {{ scope.row.comments }}
						el-tooltip(v-else="")
							template(slot="content")
								p(style="width:200px") {{scope.row.comments}}
							span.chooseable() {{ scope.row.comments.substr(0, 50) }}...
				el-table-column(align='center' label="Actions" width="140" )
					template(slot-scope="scope")
						el-tooltip(content="Set Override" )
							el-button.small-btn-action(type="info", icon="el-icon-edit", size="small", @click="setOverride(scope.row)")
						el-tooltip(content="Remove Entry")
							el-button.small-btn-action(type="danger", icon="el-icon-delete", size="small", @click="removeEntry(scope.row.ext_id)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

				el-row.mt-4
					el-col(:span="24")
						label.label-text-gray
							b Total:&nbsp;
							| {{ count | numeral('0,0') }}

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="15 Per Page", :value="15")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")
		#facebook-pixels-modal
			el-dialog(:title='"Facebook Pixel Entry" + (action === "edit" ? ` - #${currentEntry.ext_id}`: "")', :visible.sync='updateEntryModal',  center='' append-to-body="")
				p You can add a new Facebook Pixel entry here. Please, fill in the required fields and click the "Submit" button to save the entry. Make sure that the pixel is set to the relevant category as it affects the application through which the requests will be sent to the FB API.
				el-form.mt-3(:form="form" :model="form" :rules="rules" ref="form" )
					el-row.text-left(:gutter="12")
						el-col(:xs="24" :sm="8")
							el-form-item(label="Pixel ID" prop="ext_id")
								el-input(v-model="form.ext_id" placeholder="Enter the Pixel ID" clearable="")
						el-col(:xs="24" :sm="8")
							el-form-item(label="Name" prop="name")
								el-input(v-model="form.name" placeholder="Enter the Pixel Name" clearable="")
						el-col(:xs="24" :sm="8")
							el-form-item(label="Owner" prop="owner")
								el-select.w-100(v-model="form.owner" filterable="" placeholder="Select an Owner")
									el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")
						el-col(:xs="24" :sm="8")
							el-form-item(label="Category" prop="category")
								el-select.w-100(v-model="form.category" filterable="" placeholder="Select a Category")
									el-option(v-for="item in categories" :key="item.key" :label="item.label" :value="item.key")
						el-col(:xs="24" :sm="16")
							el-form-item(label="Access Token" prop="access_token")
								el-input(v-model="form.access_token" placeholder="Enter the Access Token" clearable="" type="password" show-password="" )
						el-col(:span="24")
							el-form-item(label="Comments" prop="comments")
								el-input(v-model="form.comments" type="textarea" placeholder="Enter any comments or notes about the pixel" clearable="" :rows="4" show-word-limit="" maxlength="500" :maxlength="4096")
						el-col(:xs="12" :sm="6")
							el-form-item(label="In Use" prop="in_use")
								el-switch.w-100(v-model="form.in_use" active-text="Yes" inactive-text="No")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Use Own Token" prop="use_own_token")
								el-switch.w-100(v-model="form.use_own_token" active-text="Yes" inactive-text="No")
				el-row.mt-3(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="updateEntryModal = false") Cancel

</template>

<style lang="scss">

</style>


<script>

import Vue from "vue";
import {validateNumericAndExists} from "@/utils/validate";

const $form = {
	ext_id: '',
	name: '',
	access_token: '',
	use_own_token: false,
	in_use: false,
	comments: '',
	owner: '',
};

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	return body;
};

export default {
	name: 'facebook-pixels',
	props: {
		categories: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			busy: false,
			currentEntry: null,
			updateEntryModal: false,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			filters: {
				in_use: '',
				owner: '',
				category: ''
			},
			action: null,
			form: JSON.parse(JSON.stringify($form)),
			list: [],
			count: 0,
			rules: {
				ext_id: { required: true, trigger: 'blur', validator: validateNumericAndExists, topic: 'fb-pixel', app: this,},
				name: { required: true, trigger: 'blur'},
				uuid: { required: true, trigger: 'blur'},
				comments: { required: false, trigger: 'blur'},
				notes: { required: false, trigger: 'blur'}
			},
			options: {
				users: []
			},
		};
	},
	mounted() {
		this.fetchData();
		this.getOptions();
	},
	methods: {
		setOverride(entry) {
			this.form = JSON.parse(JSON.stringify({
				...$form,
				name: entry.name,
				ext_id: entry.ext_id,
				use_own_token: entry.use_own_token,
				access_token: entry.access_token,
				comments: entry.comments,
				in_use: entry.in_use,
				owner: entry.owner ? entry.owner.uuid : '',
				category: entry.category ? entry.category.uuid : ''
			}));
			this.action = 'edit';
			this.updateEntryModal = true;
			this.currentEntry = entry;
			this.rules.ext_id.original = entry.ext_id;
			this.rules.ext_id.action = 'edit'
		},
		setCreate() {
			this.form = JSON.parse(JSON.stringify($form));
			this.action = 'create';
			this.updateEntryModal = true;
			this.rules.ext_id.action = 'create'
			this.rules.ext_id.original = undefined;
			this.currentEntry = null
		},
		getOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '6e1430cf-6c24-4da7-aeaf-a63579441e45' })
					.then(options => {
						this.options.users= options.users;
					})
		},
		fetchData() {
			setTimeout(() => {
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'fb-pixel/list', Object.assign({}, {...this.paginate}, {...this.filters}))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
							this.busy = false;
						})
						.catch(err => {
							console.error('[BLACKLIST-ADDRESSES](fetchData) Error: ', err);
							this.busy = false;
						})
			}, 0)
		},
		submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				try {
					if (this.action === 'create') {
						let result = await Vue.apix.sendHttpRequest('POST', `fb-pixel/create`, {...clearBeforeSend(this.form)})
						if (result.ext_id) {
							this.updateEntryModal = false;
							this.fetchData();
						}
					} else if (this.action === 'edit') {
						const payload = {...this.form, original_ext_id: this.currentEntry.ext_id};
						let result = await Vue.apix.sendHttpRequest('PUT', `fb-pixel/update`, {...clearBeforeSend(payload)})
						this.updateEntryModal = false;
						this.fetchData();
					}
				} catch (e) {
					console.error(e);
				}
			})
		},
		removeEntry(ext_id) {
			this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Facebook Pixel Delete Alert')
					.then(async () => {
						this.busy = true;
						return this.$apix.sendHttpRequest('DELETE', 'fb-pixel/remove', {ext_id})
								.then(resp => {
									this.busy = false;
									if (resp.success) this.$notify.success({
										title: 'API Response',
										message: 'The Integration Category entry was deleted successfully'
									})
									this.fetchData();
									this.$emit('category-change', 1)
								})
								.catch(err => {
									console.error(err);
									this.busy = false;
								})
					})
					.catch(err => {
						console.error(err);
						this.busy = false;
					})
		},
	},
};
</script>