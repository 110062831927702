<template lang="pug">
	#blacklist-address-root
		#blacklist-address-main
			.text-left
				h5 iFrame Blacklist Configuration
				p Here you can see the list of the IP addresses that were blocked dou to rate limiting policies. You can change the threshold of the rate limiter, change the limit of the requests, clear or add IP addresses manually.
				p Protected entries are entries that won't be cleaned up on a timer basis in case it is on. Manually created entries won't be assigned neither to a visit nor to a brand/funnel pair.


				label.label-text-gray Entries
				el-tooltip(content="Manually add an address to the block list")
					el-button.ml-3(type="info" icon="el-icon-plus" @click="setCreate" size="mini") Add

			el-form
				el-row.mt-2(:gutter="12")
					el-col(:xs="12" :sm="6")
						el-form-item(label="Search...")
							el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

					el-col(:xs="12" :sm="6")
						el-form-item(label="Brand")
							el-select.w-100(filterable="" v-model="filters.brand_id" @change="handleChangeAndRefresh('brand')")
								el-option(label="Filter by brand" value="")
								el-option(v-for="o of options.brand" :label="o.t" :value="o.v")
					el-col(:xs="12" :sm="6")
						el-form-item(label="Funnel")
							el-select.w-100(filterable="" v-model="filters.funnel_id"  :disabled="!filters.brand_id" @change="handleChangeAndRefresh")
								el-option(label="Filter by funnel" value="")
								el-option(v-if="o.b === filters.brand_id" v-for="o of options.funnel" :label="o.t + ' ' + (o.n ? `- (${o.it})`:'')" :value="o.v")
					el-col(:xs="12" :sm="6")
						el-form-item(label="Protected")
							el-select.w-100(v-model="filters.protected_val" @change="handleChangeAndRefresh")
								el-option(label="All" value="" )
								el-option(label="Yes" value="1")
								el-option(label="No" value="0" )

			//el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' @selection-change="handleSelectionChange" )
			el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' )
				//el-table-column(type="selection" :width="55"  )    /// todo - add bulk support

				el-table-column(align='center', label='Address')
					template(slot-scope='scope')
						span {{ scope.row.ip_addr }}
				el-table-column(align="center" label="Protected")
					template(slot-scope='scope')
						font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!scope.row.protected_val", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")
				el-table-column(align="center" label="Brand")
					template(slot-scope='scope')
						el-tooltip(v-if="scope.row.brand_id")
							template(slot="content" )
								label.d-block <b>ID:</b> {{scope.row.brand.id}}
								label.d-block <b>Name:</b> {{scope.row.brand.name}}
							span(v-if="scope.row.brand_id") {{ scope.row.brand.name }}
						span.text-info(v-if="!scope.row.brand_id")
							i No Brand
				el-table-column(align="center" label="Funnel")
					template(slot-scope='scope')
						el-tooltip(v-if="scope.row.funnel_id")
							template(slot="content")
								label.d-block <b>ID:</b> {{scope.row.funnel.id}}
								label.d-block <b>Name:</b> {{scope.row.funnel.name}}
								label.d-block <b>Note:</b> {{scope.row.funnel.note}}
							span(v-if="scope.row.funnel_id") {{ scope.row.funnel.name }}
						span.text-info(v-if="!scope.row.funnel_id")
							i No Funnel
				el-table-column(align="center" label="Visit")
					template(slot-scope='scope')
						span(v-if="scope.row.visit_id") {{ scope.row.visit_id }}
						span.text-info(v-if="!scope.row.visit_id")
							i No Visit
				el-table-column(align="center" label="Notes")
					template(slot-scope='scope')
						el-popover(
							v-if="scope.row.notes"
							placement="top-start"
							title="Entry Notes"
							:width="350"
							trigger="hover")
							div()
								p {{scope.row.notes}}
							span.chooseable( slot="reference") Hover To Show
						span.text-info(v-if="!scope.row.notes")
							i No Notes
						//span {{ scope.row.notes }}

				el-table-column(align='center' label="Actions")
					template(slot-scope="scope")
						el-tooltip(content="Set Override")
							el-button.small-btn-action(type="info", icon="el-icon-edit", size="small", @click="setOverride(scope.row)")
						el-tooltip(content="Remove Entry")
							el-button.small-btn-action(type="danger", icon="el-icon-delete", size="small", @click="removeEntry(scope.row.ip_addr)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

				el-row.mt-4
					el-col(:span="24")
						label.label-text-gray
							b Total:&nbsp;
							| {{ count | numeral('0,0') }}

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="15 Per Page", :value="15")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")
		#blacklist-address-modal
			el-dialog(:title='"Override Blacklisted Entry" + (action === "edit" ? ` - ${currentEntry.ip_addr}`: "")', :visible.sync='updateEntryModal',  center='' append-to-body="")
				p You can either change the blocked IP address, set it to be protected from automatic cleaning in case it is set or add notes. What do you want to do?
				el-form.mt-3(:form="form" :model="form" :rules="rules" ref="form" )
					el-row.text-left(:gutter="12")
						el-col(:span="16")
							el-form-item(label="IP Source" prop="ip_addr")
								el-input(v-model="form.ip_addr" maxlength="32" show-word-limit="")
						el-col(:span="8")
							el-form-item(label="Protected")
								el-switch.w-100(v-model="form.protected_val" active-text="On" inactive-text="Off")
						el-col(:span="24")
							el-form-item(label="Notes" prop="notes")
								el-input(v-model="form.notes" maxlength="2048" show-word-limit="" type="textarea" :rows="5" )
				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="updateEntryModal = false") Cancel

</template>

<style lang="scss">

</style>

<script lang="js">
import { validateIPAndExists } from "@/utils/validate";
import Vue from "vue";

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	return body;
};

export default {
	name: 'blacklist-addresses',
	data() {
		return {
			busy: false,
			fetchWatcher: null,
			currentEntry: null,
			updateEntryModal: false,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			filters: {
				brand_id: '',
				funnel_id: '',
				protected_val: ''
			},
			action: null,
			form: {
				ip_addr: '',
				notes: '',
				protected_val: true
			},
			options: {
				brand: [],
				funnel: []
			},
			list: [],
			count: 0,
			rules: {
				ip_addr: { required: true, trigger: 'blur', validator: validateIPAndExists, topic: 'blacklist-address', app: this,},
				notes: { required: false, trigger: 'blur'}
			},
		}
	},
	mounted() {
		this.fetchData();
		this.fetchWatcher = setInterval(() => {
			this.fetchData();
		}, 30000);
		this.getOptions();
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher);
	},
	methods: {
		handleChangeAndRefresh(type) {
			if(type === 'brand') {
				if(this.filters.brand_id === '') this.filters.funnel_id = '';
			}
			this.fetchData();
		},
		getOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6' })
					.then(options => {
						this.options.brand= options.brand;
						this.options.funnel = options.funnel_extra;
					})
		},
		fetchData() {
			setTimeout(() => {
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'blacklist-address/list', Object.assign({}, {...this.paginate}, {...this.filters}))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
							this.busy = false;
						})
						.catch(err => {
							console.error('[BLACKLIST-ADDRESSES](fetchData) Error: ', err);
							this.busy = false;
						})
			}, 0)
		},
		setCreate() {
			this.action = 'create';
			this.updateEntryModal = true;
			this.rules.ip_addr.action = 'create'
			this.rules.ip_addr.original = undefined;
			this.currentEntry = null
		},
		setOverride(entry) {
			this.action = 'edit';
			this.updateEntryModal = true;
			this.currentEntry = entry;
			this.rules.ip_addr.original = entry.ip_addr
			this.rules.ip_addr.action = 'edit'
		},
		async submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				try {
					if (this.action === 'create') {
						let result = await Vue.apix.sendHttpRequest('POST', `blacklist-address/create`, {...clearBeforeSend(this.form)})
						if (result.ip_addr) {
							this.updateEntryModal = false;
							this.fetchData();
						}
					} else if (this.action === 'edit') {
						const payload = {...this.form, original_addr: this.currentEntry.ip_addr};
						let result = await Vue.apix.sendHttpRequest('PUT', `blacklist-address/update`, {...clearBeforeSend(payload)})
						this.updateEntryModal = false;
						this.fetchData();
					}
				} catch (e) {
					console.error(e);
				}
			})
		},
		removeEntry(ip_addr) {
			this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Blacklist Address Delete Alert')
					.then(async () => {
						this.busy = true;
						return this.$apix.sendHttpRequest('DELETE', 'blacklist-address/remove', {ip: ip_addr})
								.then(resp => {
									this.busy = false;
									if (resp.success) this.$notify.success({
										title: 'API Response',
										message: 'The Blacklist Address entry was deleted successfully'
									})
									this.fetchData()
								})
								.catch(err => {
									console.error(err);
									this.busy = false;
								})
					})
					.catch(err => {
						console.error(err);
						this.busy = false;
					})
		},
		handleSelectionChange() {

		}
	}
}
</script>