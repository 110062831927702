<template lang="pug">
	#custom-fix-root
		#custom-fix-body
			el-row.text-left
				el-form()
					el-row.text-left
						//pre mode: {{ mode }}
						h4 Custom Fix Settings
						p In this section you can find custom fix scripts and configurations. A custom fix entry holds inside a script that the integration application&nbsp;
							| runs in case it is set. The script itself must be an exportable module (Supports CJS, while ESM is currently not supported). &nbsp;
							| The goal of this project is to support writing simple scripts that manipulate lead's data before being sent to a remote API or written to the database.

						p Custom Fix entries affect on Google Sheets integrations, API and iFrame integrations, in case they are toggled.
					el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-form-item(label="Templates")
								el-button(type="info" icon="el-icon-plus" @click="toggleModal(0)") Add
					el-table(id="custom-fix-tbl" v-loading="busy" stripe="" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align="center" label="Topic")
							template(slot-scope="scope")
								span() {{ scope.row.topic }}
						el-table-column(align="center" label="Timestamp")
							template(slot-scope="scope")
								span() {{ scope.row.createdAt | formatDateTimeShort }}
						el-table-column(align="center" label="Description")
							template(slot-scope="scope")
								span() {{ scope.row.description }}
						el-table-column(align="center" label="Params")
							template(slot-scope="scope")
								span.chooseable Hover to show
						el-table-column(align="center" label="Status")
							template(slot-scope="scope")
								font-awesome-icon.icon.alt.big-icon.text-success(v-if="scope.row.in_use" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'plus']")
								font-awesome-icon.icon.alt.big-icon.text-danger(v-else="" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'minus']")
						el-table-column(align="center" label="Actions" :width="120")
							template(slot-scope="scope" )
								el-tooltip(content="Toggle the custom fix on or off")
									el-button.small-btn-action(type="info", icon="el-icon-refresh", size="small", @click="toggleEntry(scope.row.id)")
								el-tooltip(content="Edit this custom fix")
									el-button.small-btn-action(type="warning", icon="el-icon-edit", size="small", @click="toggleModal(1, scope.row)")
								el-tooltip(content="Delete this custom fix")
									el-button.small-btn-action(type="danger", icon="el-icon-remove", size="small", @click="removeEntry(scope.row.id)")

					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="pagination.page", :total-rows="count", :per-page="pagination.limit", aria-controls="data-list", @change="fetchData")

		#custom-fix-modals
			el-dialog.override-width.search-dialog(:title="`${!mode?'Create new':'Edit existing'} entry${mode?` - ${currentEntry.topic}`:''}`" :visible.sync='showAddCustomFixModal', width='75%', center='' @closed="cancelForm")
				el-row(:gutter="24")
					el-col(:span="24")
						el-row(:gutter="24")
							h5 Custom Fix Form
							p Fill the following form to create a custom fix entry - this will create a file in each of the projects. The function mentioned in the scripts part will be launched just&nbsp;
								| before been sent to the remote API or written to the database.
							p Supported fields are:&nbsp;
								el-tag(v-for="e of ['params','email','first_name','last_name','password','country','phone','ip','language','guid','useragent']" :key="e") {{e}}
					el-col(:span="24")
						el-form(:form="form" :rules="rules" ref="cust-fix-form" :model="form")
							el-row(:gutter="12")
								el-col(:xs="24" :sm="12")
									el-form-item(prop="topic" ref="_formTopic")
										template(slot="label")
											label Topic&nbsp;
											small.text-success() (Name of the file - must be unique)

										el-input( v-model="form.topic")
								el-col(:xs="24" :sm="4")
									el-form-item()
										template(slot="label")
											label Toggle&nbsp;
											small.text-success (Is on)
										el-radio-group.w-100(v-model="form.in_use" :fill="form.in_use === true ? '#409EFF' : form.in_use === -1 ? '#c9c9c9' : 'red'")
											el-radio-button(:label="true") On
											el-radio-button(:label="false") Off
								el-col(:span="24")
									el-form-item(label="Description" prop="description" ref="_formDescription")
										el-input(type="textarea" :rows="3" v-model="form.description")
								el-col(:xs="24" :sm="24")
									el-form-item()
										template(slot="label")
											label Script&nbsp;
											small.text-success (Supports only JavaScript)
										CodeEditor(:wrap_code="true" v-model="form.script" z_index="6" width="100%" height="50vh" :languages="[['javascript', 'JS']]")
								//el-col(:xs="24" :sm="6")
									el-form-item(label="Params")
										label.text-warning To Be Implemented
				span.dialog-footer(slot='footer')
					el-button(type='primary', @click='submitCustomModalChanges') Submit
					el-button(type='danger', @click='cancelForm') Cancel
</template>

<style lang="scss">
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
	.small-btn-action {
		//padding: 9px 11px
		padding: 6.5px;
		margin-left: 0.3rem !important;

		&:first-child {
			margin-left: 0;
		}
	}

</style>


<script>
import CodeEditor from 'simple-code-editor';
import {validateAlphaNumeric} from "../../utils/validate";

const $form = {
	topic: '',
	description: '',
	in_use: false,
	params: [],
	script: '/**\n' +
			' * Lorem Ipsum Dolor Sit Amet\n' +
			' * @param entry: any\n' +
			' * @param params: any\n' +
			' * @param cb: function\n' +
			' * @return {string}\n' +
			' */\n' +
			'module.exports = function (entry, params, cb) {\n' +
			'    // Write down some logic here\n' +
			'    let result;\n' +
			'\n' +
			'    // You may either launch a callback function or return the computed result.\n' +
			'    if(cb) cb();\n' +
			'    else return result;\n' +
			'}',
};
function clearUnnecessary(body) {
	const data = JSON.parse(JSON.stringify(body));
	delete data.createdAt;
	return data;
}


export default {
	name: 'custom-fix',
	components: { CodeEditor },
	data() {
		return {
			showAddCustomFixModal: false,
			currentEntry: null,
			list: [],
			count: 0,
			pagination: {
				keyword: '',
				limit: 5,
				page: 1
			},
			rules: {
				topic: { required: true, validator: validateAlphaNumeric },
				description: { required: true, validator: validateAlphaNumeric },
				script: { required: true, validator: validateAlphaNumeric },
			},
			busy: false,
			mode: 0, // 0 - create, 1 - update,
			form: JSON.parse(JSON.stringify($form))
		}
	},
	mounted() {
		this.fetchData()
	},

	methods: {
		fetchData() {
			this.$apix.sendHttpRequest('GET', 'custom-fix/get', JSON.parse(JSON.stringify(this.pagination)))
					.then((res) => {
						this.list = res.rows;
						this.count = res.count;
					})
		},
		toggleModal(mode, entry) {
			this.mode = mode;
			this.showAddCustomFixModal = true;
			this.form = JSON.parse(JSON.stringify($form));
			if(mode) {
				this.currentEntry = entry;
				this.form = JSON.parse(JSON.stringify(entry));
			} else {
				this.form = JSON.parse(JSON.stringify($form));
			}
		},
		submitCustomModalChanges() {
			// this.$notify.success({title: 'test', message: 'lorem'});
			this.$refs['cust-fix-form'].validate(valid => {
				if (!valid) return this.$message.error('Validation failed');
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'custom-fix/set', clearUnnecessary(this.form))
						.then(res => {
							loader.hide();
							if(res === 'ok') {
								this.showAddCustomFixModal = false;
								this.form = JSON.parse(JSON.stringify($form));
								this.fetchData();
							}
						})
						.catch(err => {
							console.error(err);
							loader.hide();
						})
			})

		},
		cancelForm() {
			this.showAddCustomFixModal = false;
			this.form = JSON.parse(JSON.stringify($form));
		},

		toggleEntry(id) {
			this.$apix.sendHttpRequest('PUT', 'custom-fix/toggle/' + id)
					.then(() => {
						this.fetchData();
					})
		},
		removeEntry(id) {
			this.$apix.sendHttpRequest('DELETE', 'custom-fix/' + id)
					.then(() => {
						this.fetchData();
					})
		},
	}
}
</script>