<template lang="pug">
	#categories-root
		#categories-main
			.text-left
				h5 Facebook Pixel Categories
				p Here you can manage the integration categories. Aim of a category is to differentiate between different types of integrations, specifically Facebook Pixel integrations.

				label.label-text-gray Entries
				el-tooltip(content="Manually add an category to the list")
					el-button.ml-3(type="info" icon="el-icon-plus" @click="setCreate" size="mini") Add

			el-form
				el-row.mt-2(:gutter="12")
					el-col(:xs="12" :sm="6")
						el-form-item(label="Search...")
							el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

					el-col(:xs="12" :sm="6")
						el-form-item(label="In Use")
							el-select.w-100(v-model="filters.in_use" @change="fetchData")
								el-option(label="All" value="" )
								el-option(label="Yes" value="1")
								el-option(label="No" value="0" )
					el-col(:xs="12" :sm="6")
						el-form-item(label="Owners")
							el-select.w-100(filterable="" v-model="filters.owner" @change="fetchData")
								el-option(label="- None, Select an owner -" :value="''")
								el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")
			el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' )
				el-table-column(align='center', label='#' :width="80")
					template(slot-scope='scope')
						span {{ scope.row.id }}
				el-table-column(align='center', label='Name')
					template(slot-scope='scope')
						span {{ scope.row.name }}&nbsp;
							small.text-success ({{ scope.row.notes }})
				el-table-column(align='center', label='UUID')
					template(slot-scope='scope')
						el-tooltip(content="You should use this uuid with the pixel if you want to fire a conversion hook to Facebook. You should concatenate the pixel with the uuid in the following way: {pixel_id}::{uuid}" )
							span {{ scope.row.uuid }}

				el-table-column(align='center', label='Comments')
					template(slot-scope='scope')
						span(v-if="scope.row.comments.length <= 50") {{ scope.row.comments }}
						el-tooltip(v-else="")
							template(slot="content")
								p(style="width:200px") {{scope.row.comments}}
							span.chooseable() {{ scope.row.comments.substr(0, 50) }}...

				el-table-column(align="center" label="Protected" :width="100")
					template(slot-scope='scope')
						font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!scope.row.protected", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")

				el-table-column(align='center', label='Owner')
					template(slot-scope="scope" )
						el-tooltip(v-if="scope.row.user_id")
							template(slot="content")
								span.d-block <b>Name: </b> {{scope.row.owner.first_name}} {{scope.row.owner.last_name}}
								span.d-block <b>Email: </b>{{scope.row.owner.email}}
								span.d-block <b>Username: </b>{{scope.row.owner.username}}
							span.chooseable {{scope.row.owner.username}}
						span.text-info(v-else="")
							i No Owner
				el-table-column(align='center' label="Actions" :width="140")
					template(slot-scope="scope")
						el-tooltip(content="Set Override" v-if="!scope.row.protected")
							el-button.small-btn-action(type="info", icon="el-icon-edit", size="small", @click="setOverride(scope.row)")
						el-tooltip(content="Remove Entry" v-if="!scope.row.protected")
							el-button.small-btn-action(type="danger", icon="el-icon-delete", size="small", @click="removeEntry(scope.row.name)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

				el-row.mt-4
					el-col(:span="24")
						label.label-text-gray
							b Total:&nbsp;
							| {{ count | numeral('0,0') }}

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="15 Per Page", :value="15")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")

		#blacklist-address-modal
			el-dialog(:title='"Integration Category Entry" + (action === "edit" ? ` - ${currentEntry.id}`: "")', :visible.sync='updateEntryModal',  center='' append-to-body="")
				p Categories are meant to be used for different purposes, at this moment, to differentioate between Facebook integration accounts. Use with care.&nbsp;
					| Keep in mind that the names are unique. You can generate a UUID, however, you cannot type something custom.
				p You may assign a category to a specific user. Nevertheless, it has no effect in the application, it is meant for differentiation.
				el-form.mt-3(:form="form" :model="form" :rules="rules" ref="form" )
					el-row.text-left(:gutter="12")
						el-col(:xs="24" :sm="8")
							el-form-item(label="Name" prop="name")
								el-input(v-model="form.name" show-word-limit="" :maxlength="64" placeholder="Unique Name")
						el-col(:xs="24" :sm="16" :md="8")
							el-form-item(label="UUID" prop="uuid")
								el-input(v-model="form.uuid" readonly="" placeholder="Click to generate" )
									el-button(slot="append" icon="el-icon-refresh" @click="generateUuid('uuid')")
						el-col(:xs="24" :sm="16" :md="8")
							el-form-item(label="Notes" prop="notes")
								el-input(v-model="form.notes" maxlength="128" show-word-limit="" placeholder="Notes about the category" )
						el-col(:xs="24" :sm="16" :md="8")
							el-form-item(label="Owner")
								el-select.w-100(filterable="" v-model="form.owner")
									el-option(label="- None, Select an owner -" :value="''")
									el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")
						el-col(:xs="24" :sm="6" :md="4")
							el-form-item(label="In Use")
								el-switch.w-100(v-model="form.in_use" active-text="On" inactive-text="Off")
						el-col(:span="24")
							el-form-item(label="Comments" prop="comments")
								el-input(type="textarea" :rows="3" v-model="form.comments" maxlength="4096" show-word-limit="" placeholder="Lorem ipsum dolor sit amet...." )
				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="updateEntryModal = false") Cancel
</template>

<script lang="js">
import {v4} from "uuid";
import {validateAlphaNumericAndExists, validateIPAndExists} from "@/utils/validate";
import Vue from "vue";


const $form = {
	name: '',
	notes: '',
	uuid: '',
	comments: '',
	in_use: false,
	owner: ''
}

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	return body;
};

export default {
	name: 'integration-categories',
	data() {
		return {
			busy: false,
			currentEntry: null,
			updateEntryModal: false,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			filters: {
				in_use: '',
				owner: ''
			},
			action: null,
			form: JSON.parse(JSON.stringify($form)),
			list: [],
			count: 0,
			rules: {
				name: { required: true, trigger: 'blur', validator: validateAlphaNumericAndExists, topic: 'category', app: this,},
				uuid: { required: true, trigger: 'blur'},
				comments: { required: false, trigger: 'blur'},
				notes: { required: false, trigger: 'blur'}
			},
			options: {
				users: []
			}
		}
	},
	mounted() {
		this.fetchData();
		this.getOptions();
	},
	methods: {
		generateUuid() {
			this.form.uuid = v4();
		},
		getOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '6e1430cf-6c24-4da7-aeaf-a63579441e45' })
					.then(options => {
						this.options.users= options.users;
					})
		},
		fetchData() {
			setTimeout(() => {
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'category/list', Object.assign({}, {...this.paginate}, {...this.filters}))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
							this.busy = false;
						})
						.catch(err => {
							console.error('[BLACKLIST-ADDRESSES](fetchData) Error: ', err);
							this.busy = false;
						})
			}, 0)
		},

		setOverride(entry) {
			this.form = JSON.parse(JSON.stringify({
				...$form,
				name: entry.name,
				uuid: entry.uuid,
				notes: entry.notes,
				comments: entry.comments,
				in_use: entry.in_use,
				owner: entry.owner ? entry.owner.uuid : ''
			}));
			this.action = 'edit';
			this.updateEntryModal = true;
			this.currentEntry = entry;
			this.rules.name.original = entry.name
			this.rules.name.action = 'edit'
		},
		setCreate() {
			this.form = JSON.parse(JSON.stringify($form));
			this.action = 'create';
			this.updateEntryModal = true;
			this.rules.name.action = 'create'
			this.rules.name.original = undefined;
			this.currentEntry = null
		},
		submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				try {
					if (this.action === 'create') {
						let result = await Vue.apix.sendHttpRequest('POST', `category/create`, {...clearBeforeSend(this.form)})
						if (result.id) {
							this.updateEntryModal = false;
							this.fetchData();
							this.$emit('category-change', 1)
						}
					} else if (this.action === 'edit') {
						const payload = {...this.form, original_name: this.currentEntry.name};
						let result = await Vue.apix.sendHttpRequest('PUT', `category/update`, {...clearBeforeSend(payload)})
						this.updateEntryModal = false;
						this.fetchData();
						this.$emit('category-change', 1)
					}
				} catch (e) {
					console.error(e);
				}
			})
		},
		removeEntry(name) {
			this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Integration Category Delete Alert')
					.then(async () => {
						this.busy = true;
						return this.$apix.sendHttpRequest('DELETE', 'category/remove', {name})
								.then(resp => {
									this.busy = false;
									if (resp.success) this.$notify.success({
										title: 'API Response',
										message: 'The Integration Category entry was deleted successfully'
									})
									this.fetchData();
									this.$emit('category-change', 1)
								})
								.catch(err => {
									console.error(err);
									this.busy = false;
								})
					})
					.catch(err => {
						console.error(err);
						this.busy = false;
					})
		},
	},
}
</script>