<template lang="pug">
	#language-settings-root
		#language-settings-body
			el-row.text-letf
				el-form()
					el-row.text-left
						h4 Language Settings
						p You can turn on and off languages, add, remove or update the translations and change or add export overrides - whenever a user exports a file to an Excel spreadsheet, the original language will be overwritten by the one that is set here.
						p Click on a language to show it's translations or click on the update button

					el-row.mt-5(:gutter="24")
						el-col(:xs="24" :md="12" :lg="10")
							h5 Language List

							el-row.mt-2(:gutter="24")
								el-col(:span="12")
									el-form-item(label="Search...")
										el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")
								el-col(:span="12")
									el-form-item(label="Integration")
										el-select.w-100( filterable="" v-model="filters.is_used" placeholder="In Use / Off" @change="fetchData")
											el-option(label="all" value="all")
											el-option(label="In Use" :value="1")
											el-option(label="Off" :value="0")

							el-table(id="data-list-languages", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
								el-table-column(align="center" label="ISO")
									template(slot-scope='scope')
										span {{ scope.row.iso }}
								el-table-column(align="center" label="Full Name")
									template(slot-scope='scope')
										span {{ scope.row.full }}
								el-table-column(align="center" label="In Use")
									template(slot-scope='scope')
										el-tooltip(:content="scope.row.is_used ? 'In use by the system' : 'Not In Use'")
											font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.is_used", :icon="['fas', 'times']")
											font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
								el-table-column(align="center" label="Export Override")
									template(slot-scope="scope")
										span(v-if="scope.row.export_override") {{scope.row.export_override}}
										span.text-info(v-else="")
											i No Override
								el-table-column(align='center' label="Actions")
									template(slot-scope="scope")
										el-tooltip(content="Set Override")
											el-button.small-btn-action(type="warning", icon="el-icon-edit", size="small", @click="setOverride(scope.row)")
										el-tooltip(content="Show Translations")
											el-button.small-btn-action(type="info", icon="el-icon-tickets", size="small", @click="showTranslations(scope.row.iso)")
							.d-flex.justify-content-between
								b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

								el-row.mt-4
									el-col(:span="24")
										label.label-text-gray
											b Total:&nbsp;
											| {{ count | numeral('0,0') }}

								el-row
									el-col(:span="24")
										el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
											el-option(label="10 Per Page", :value="10")
											el-option(label="15 Per Page", :value="15")
											el-option(label="20 Per Page", :value="20")
											el-option(label="50 Per Page", :value="50")
											el-option(label="100 Per Page", :value="100")
											el-option(label="200 Per Page", :value="200")
						el-col(:xs="24" :md="12" :lg="14")
							h5 Translations
							p Click on a language to show the translations {{ currentLanguageTranslations ? `(${currentLanguageTranslations})` : '' }}
							el-row.mt-5(:gutter="24")
								el-empty(description="No Translations For This Language..." :image-size="50" v-if="!hasTranslations")
								//pre {{translations}}
								el-col(v-for="t of translations" :xs="12" :md="8" :lg="6")
									el-form-item(:label="t.key")
										el-input(readonly="" :value="t.val")


		#language-settings-modals
			//el-dialog(title='Set Override', :visible.sync='showLanguageOverrideModal',  center='')



</template>


<style lang="scss">

</style>


<script>

function clearEmpty(data) {
	let obj = {};
	Object.entries(data).forEach(e => {
		if(e[1] !== '' && e[1] !== null && e[1] !== undefined)
			obj[e[0]] = e[1];
	});
	return obj;
}

export default {
	name: 'language-settings',
	data() {
		return {
			hasTranslations: true,
			currentLanguage: null,
			currentLanguageTranslations: null,
			showLanguageOverrideModal: false,
			busy: false,
			languages: [],
			paginate: {
				limit: 15,
				page: 1,
				keyword: '',
			},
			filters: {
				is_used: 'all'
			},
			form: {
				export_override: '',
			},
			translations: {

			},
			list: [],
			count: 0
		}
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			setTimeout(() => {
				const body = {...this.paginate, ...clearEmpty(this.filters)};
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'languages/list', body)
						.then(res => {
							this.busy = false
							this.list = res.rows;
							this.count = res.count;
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
			}, 200);
		},
		setOverrideOld(obj) {
			this.form.export_override = '';
			this.currentLanguage = obj.id;
			this.showLanguageOverrideModal = true;
		},
		setOverride(obj) {
			this.$prompt('Type the exact language ISO or key that you want to override during Excel exports. Type ::NULL:: if you want to remove the export override param.', 'Override Export Param', {
				confirmButtonText: 'Save',
				cancelButtonText: 'Cancel',
				inputPattern: /^(?:[a-zA-Z-_]{2,10}|::NULL::)$/,
				inputErrorMessage: 'Must be 2-10 characters in Latin'
			})
					.then(prompt => {
						const loader = this.$loading.show();
						this.$apix.sendHttpRequest('PUT', 'languages/set-export-override/' + obj.iso, { export_override: prompt.value })
								.then(res => {
									this.$notify({type: 'success', message: 'Export override param was set successfully'})
									loader.hide();
									this.fetchData();
								})
								.catch(err => {
									this.$message({
										type: 'error',
										message: 'Export override failed'
									});
									loader.hide();
								})
					})
					.catch(err => {
						console.error(err);
						this.busy = false;
					});
		},
		showTranslations(iso) {
			this.currentLanguageTranslations = iso;
			this.$apix.sendHttpRequest('GET', 'languages/translations/' + iso)
					.then(res => {

						this.hasTranslations = true;
						if(Object.keys(res).length === 0) this.hasTranslations = false;
						this.translations = Object.entries(res).map(e => ({key: e[0], val: e[1]}))
					})
		}
	}
}
</script>