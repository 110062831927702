<template lang="pug">
	#application-settings-root
		#application-settings-main
			el-row.text-left
				h4 Application settings
				p In this section you can change various settings related to the applications. Please note - some of the settings are currently cannot override the ones in the .ENV file - this will be fixed in the future. They are readonly for now.
				//h5.text-danger Not Implemented Yet!
			//pre {{form}}
			el-form.text-left(:form="form" :model="form"  ref="form" :rules="rules" label-position="top")
				el-row.text-left()
					el-col(:span="24")
						h5 <b>System</b>
						p System settings - Common configurations, HTTP ports etc.
				el-row(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="CRM Port" prop="system_crm_port"  label-position="top")
							el-input-number.w-100(v-model="form.system.crm_port" placeholder="CRM Port" :min="3000" :max="65535" disabled="")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Jobs Port" prop="system_jobs_port")
							el-input-number.w-100(v-model="form.system.jobs_port" placeholder="Jobs Port" :min="3000" :max="65535" disabled="")

				el-row.text-left()
					el-col(:span="24")
						h5 <b>Security</b>
						p Security settings - Everything related to security.&nbsp;
							a.chooseable(@click="openBlacklistModal") Click Here to show blacklisted IP addresses (iFrame Service)
								font-awesome-icon.icon.alt.ml-2.chooseable(size="lg", :icon="['fa', 'download']")
				p
					b *Blacklist Settings (iFrame)
				p These are settings related to the IP addresses blacklist, with which you can control this security mechanism.
				ul
					li <b>Blocker On/Off</b>: Toggle on or off the blocking mechanism. If it is off, registration attemptys won't be counted, thus, won't be blocked.
					li <b>Auto-Clean Flag</b>: Will the auto-cleaner remove the entries after specific amount of time
					li <b>Auto-Clean Threshold</b>: Clean the entry after Amount of minutes, since the entry was registerd.
					li <b>Blacklist Threshold</b>: Quantity of registration attempts - After reaching the threshold, the enrtry will be registered, and the registrations from this IP will be blocked.
					li <b>Blacklist TTL</b>: Time-To-Live - For how many seconds will the system count the registration attempts. It will reset after reaching the TTL
				el-row.text-left(:gutter="24")
					el-col(:xs="12" :sm="6" :md="4")
						el-form-item(label="Blocker On/Off")
							el-switch.w-100(v-model="form.blacklist.blocker_flag" active-text="Enabled" inactive-text="Disabled" )
					el-col(:xs="12" :sm="6" :md="4")
						el-form-item(label="Auto-Clean Flag")
							el-switch.w-100(v-model="form.blacklist.auto_clean_flag" active-text="Enabled" inactive-text="Disabled" )
					el-col(:xs="12" :sm="6" prop="blacklist_auto_clean_threshold")
						el-form-item(label="Auto-Clean Threshold")
							el-input-number.w-100(v-model="form.blacklist.auto_clean_threshold"  placeholder="Threshold in minutes" :min="1" :max="10080")
					el-col(:xs="12" :sm="6" prop="blacklist_threshold")
						el-form-item(label="Blacklist Threshold")
							el-input-number.w-100(v-model="form.blacklist.threshold" placeholder="Threshold until ban" :min="1" :max="1024")
					el-col(:xs="12" :sm="6" :md="6" prop="blacklist_ttl")
						el-form-item(label="Blacklist Cache TTL")
							el-input-number.w-100(v-model="form.blacklist.ttl" placeholder="Time-To-Live in minutes (Redis)" :min="1" :max="86400")
				p.mt-2
					b *Other scurity settings
				el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :md="8")
						el-form-item(label="JWT Secret" prop="security_jwt_secret")
							el-input(v-model="form.security.jwt_secret" placeholder="JWT Secret" disabled="")
								el-button(slot="append" icon="el-icon-s-promotion" v-b-tooltip.html.hover-right="", title="Generate new secret" @click="generateNewGuid('jwt_secret')" disabled="")
					el-col(:xs="24" :sm="12" :md="8")
						el-form-item(prop="security_override_token" )
							template(slot="label")
								span Override Token&nbsp;
								small.text-success Token to override the default use of iFame's API during registration

							el-input(v-model="form.security.override_token" placeholder="Override Token" disabled="")
								el-button(slot="append" icon="el-icon-s-promotion" v-b-tooltip.html.hover-right="", title="Generate new token" @click="generateNewGuid('override_token')" disabled="")

				el-row.text-left()
					el-col(:span="24")
						h5 <b>IFrame</b>
						p IFrame settings
				el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Test Server IP" prop="iframe_test_server_ip" )
							//el-input(v-model="form.iframe.test_server_ip" placeholder="Test Server IP")
							el-select.w-100(v-model="form.iframe.test_server_ip" multiple="" filterable="" allow-create="" default-first-option="" placeholder="E.g 127.0.0.1" @change="ipAdded" )
								el-option(key="0.0.0.0" label="0.0.0.0" value="0.0.0.0") All
								el-option(:key="defaultIP" :label="defaultIP" :value="defaultIP")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Dummy Visit ID" prop="iframe_dummy_visit_id")
							el-input(v-model="form.iframe.dummy_visit_id" placeholder="Dummy Visit ID" )
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Dynamic Params" prop="iframe_dynamic_params")
							el-switch.w-100(v-model="form.iframe.dynamic_params" active-text="Enabled" inactive-text="Disabled" )
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="iFrame Domain List" label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.iframe.domain_list', closable='', :disable-transitions='false', @close='handleClose(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisible', v-model='inputValue', ref='saveTagInput', size='mini', @keyup.enter.native='handleInputConfirm', @blur='handleInputConfirm')
								el-button.button-new-tag(v-else='', size='small', @click='showInput') + New Tag

				el-divider
				el-row.mt-3.text-left()
					el-col(:span="24")
						h5 <b>Integrations</b>
						p Here you can find the settings for the various integrations - you can set the credentials of the known registrations - USE WITH CARE!!! - if you don't know what you are doing, don't touch these settings!&nbsp;
							| Any changes in these settings will be applied automatically to all the services that use them.

				el-row.text-left.mt-3()
					el-col(:span="24")
						h5 <b>Twillio</b>
				el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Twilio SID" prop="integration_twillio_sid" )
							el-input(v-model="form.integration_twillio.sid" placeholder="Twilio SID")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Twilio Token" prop="integration_twillio_token" )
							el-input(v-model="form.integration_twillio.token" placeholder="Twilio Token")
				el-row.text-left.mt-3()
					el-col(:span="24")
						h5 <b>JustClick (Proxy API)</b>
				el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="JustClick User" prop="integration_justclick_user")
							el-input(v-model="form.integration_justclick.user" placeholder="JustClick User")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="JustClick Pass" prop="integration_justclick_pass")
							el-input(v-model="form.integration_justclick.pass" placeholder="JustClick Pass" type="password" show-password="")
					el-col(:xs="24" :sm="12")
						el-form-item(label="JustClick Key" prop="integration_justclick_key")
							el-input(v-model="form.integration_justclick.key" placeholder="JustClick Key" type="password" show-password="")
				//el-row.text-left()
					el-col(:span="24")
						h5 <b>Facebook - OLD</b>
				//el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(label="Facebook App ID" prop="integration_facebook_app_id")
							el-input(v-model="form.integration_facebook.app_id" placeholder="Facebook App ID")
					el-col(:xs="24" :sm="12" )
						el-form-item(label="Facebook Access Token" prop="integration_facebook_access_token")
							el-input(v-model="form.integration_facebook.access_token" placeholder="Facebook Access Token" type="password" show-password="")

				el-row.text-left.mt-3()
					el-col(:span="24")
						h5 <b>Facebook - List</b>
						p Below is a list of all the Facebook integrations that are currently in use aggregated by categories. Click on the "Add" button to add more integrations or "Categories" to manage the categories.
							el-tooltip(content="Click to add more Facebook integrations")
								el-button.ml-4(type="success" icon="el-icon-plus" @click="form.integration_facebook.list.push({category: '', app_id: '', access_token: '', comments: '', proxy_on: true, proxy_protocol: 'HTTP', proxy_host: '127.0.0.1', proxy_port: 80})") Add
							el-tooltip(content="Show and manage categories")
								el-button(type="warning" icon="el-icon-tickets" @click="showCategoriesModal = true") Categories
							el-tooltip(content="Show and manage Facebook Pixels")
								el-button(type="info" icon="el-icon-s-grid" @click="showFbPixelsModal = true") FB Pixles
				el-row.text-left(:gutter="24")
					el-col(:xs="24" :sm="12" :xl="8" v-for="(i, index) in form.integration_facebook.list" :key="index" )
						el-card.mt-3( shadow="none")
							.clearfix.d-flex.justify-content-between(slot="header")
								el-col(:span="24")
									.d-flex.justify-content-between
										h5.header-text User {{index + 1}} ({{i.remark}})
										el-tooltip(:content="i.main?`Main user, can't delete`:'Click to remove the integration'")
											el-button(type="danger" size="mini" @click="form.integration_facebook.list.splice(index, 1)" :disabled="i.main") Remove
							el-row(:gutter="12")
								el-col(:xs="24" :md="8")
									el-form-item(label="Category" prop="integration_facebook_app_id")
										el-input(v-model="i.remark" placeholder="Remark" :maxlength="32" :show-word-limit="true")
								el-col(:xs="24" :md="8")
									el-form-item(label="Category" prop="integration_facebook_app_id")
										el-select.w-100(v-model="i.category" placeholder="Facebook App ID")
											el-option(v-for="o in options.categories" :label="o.label" :value="o.key")
											//el-option(label="Category 1 - B (#000)" value="1")
											//el-option(label="Category 2 - G (#9a9a9a)" value="2")
											//el-option(label="Category 3 - W (#fff)" value="3")
								el-col(:xs="24" :md="8")
									el-form-item(label="App ID" prop="integration_facebook_app_id")
										el-input(v-model="i.app_id" placeholder="Facebook App ID")
								el-col(:span="24")
									el-form-item(label="Access Token" prop="integration_facebook_access_token")
										el-input(v-model="i.access_token" placeholder="Facebook Access Token" type="password" show-password="")
								el-col(:span="24")
									el-form-item(label="Comments")
										el-input(v-model="i.comments" placeholder="Remarks and comments" type="textarea" :rows="2" show-word-limit="" :maxlength="1024")
							el-divider
							el-row.mt-3(:gutter="12")
								.d-flex.justify-content-between
									h5.header-text Proxy Settings
									div
										label.label-text-gray.d-inline Toggle Proxy:&nbsp;&nbsp;
										el-radio-group(v-model="i.proxy_on" :fill="i.proxy_on === true ? '#409EFF' :  'red'" size="mini")
											el-radio-button.mb-0(:label="true") On
											el-radio-button.mb-0(:label="false") Off
							el-row.mt-3(:gutter="12")
								el-col(:xs="24" :md="8")
									el-form-item(label="Protocol" :error="i.proxy_protocol === 'HTTPS' ? 'HTTPS currently not supported!' : ''" )
										el-select.w-100(v-model="i.proxy_protocol" :disabled="!i.proxy_on")
											el-option(label="SOCKS5" value="SOCKS5" selected="")
											el-option(label="HTTP" value="HTTP")
											el-option(label="HTTPS" value="HTTPS")
								el-col(:xs="24" :md="10" :lg="11")
									el-form-item(label="Host")
										el-input(v-model="i.proxy_host" :disabled="!i.proxy_on" placeholder="IPv4 address of the proxy" )
								el-col(:xs="24" :md="6" :lg="5")
									el-form-item(label="Port"  prop="integration_facebook_proxy_port")
										el-input-number.w-100(v-model="i.proxy_port" controls-position="right" :min="0" :max="65536" :disabled="!i.proxy_on")

								el-col(:xs="24" :md="8" :lg="6")
									el-form-item(label="Toggle Auth")
										el-switch.w-100(v-model="i.proxy_auth" active-text="On" inactive-text="Off" :disabled="!i.proxy_on")

								el-col(:xs="24" :md="8" :lg="9")
									el-form-item(label="Auth - Username")
										el-input(v-model="i.proxy_username" :disabled="!i.proxy_on || !i.proxy_auth" placeholder="Username" )
								el-col(:xs="24" :md="8" :lg="9")
									el-form-item(label="Auth - Password")
										el-input(v-model="i.proxy_password" :disabled="!i.proxy_on || !i.proxy_auth" placeholder="Password"  type="password" show-password="")

				el-row.mt-3()
					el-col(:span="24" )
						.d-flex.justify-content-center
							el-button(type="success" @click.prevent.native="submit" ) Save
							el-button(type="danger" @click.prevent.native="resetForm" ) Reset
		#application-settings-modal
			el-dialog.override-width.search-dialog(title="Blacklist - Settings" :visible.sync="showBlacklistModal" width="70%")
				blacklist-addresses(v-if="showBlacklistModal")

			// We may move the categories to a different place in future, currently, it is related to Facebook.
			el-dialog.override-width.search-dialog(title="Categories - Settings" :visible.sync="showCategoriesModal" width="70%" )
				integration-categories(v-if="showCategoriesModal" @category-change="handleCategoryChange")

			el-dialog.override-width.search-dialog(title="FaceBook Pixels - Settings" :visible.sync="showFbPixelsModal" width="70%" )
				facebook-pixels(v-if="showFbPixelsModal" :categories="options.categories")
</template>

<style lang="scss">

</style>

<script>

import {v4} from "uuid";
import {
	notEmpty,
	validateAlphaNumeric,
	validateIP,
	validateIsInteger,
	validateNumeric,
	validatePass, validatePort
} from "@/utils/validate";
import publicIp from "public-ip";
import validator from "validator";
import BlacklistAddresses from "@/components/blacklist-addresses.vue";
import IntegrationCategories from "@/components/integration-categories.vue";
import FacebookPixels from "@/components/facebook-pixels.vue";

const $form = {
	system: {
		crm_port: '',
		jobs_port: '',
	},
	security: {
		jwt_secret: '',
		override_token: '',
		pkurl: ''
	},
	iframe: {
		test_server_ip: '',
		dummy_visit_id: '',
		dynamic_params: false,
		domain_list: []
	},

	integration_twillio: {
		sid: '',
		token: ''
	},
	integration_justclick: {
		user: '',
		pass: '',
		key: ''
	},
	integration_facebook: {
		app_id: '',
		access_token: '',
		list: []
	},
	integration_voluum: {
		access_token: '',
		access_key: ''
	},
	blacklist: {
		blocker_flag: false,
		auto_clean_flag: false,
		auto_clean_threshold: 3600,
		threshold: 10,
		ttl: 300
	}
};

export default {
	name: 'application-settings',
	components: {BlacklistAddresses, IntegrationCategories, FacebookPixels},
	mounted() {
		this.fetchData();
		publicIp.v4().then(ip => this.defaultIP = ip);
		this.fetchOptions();
	},
	data() {
		return {
			defaultIP: '',
			showFbPixelsModal: false,
			showCategoriesModal: false,
			showBlacklistModal: false,
			form: JSON.parse(JSON.stringify($form)),
			options: {
				categories: []
			},
			original_form: {},
			inputVisible: false,
			inputValue: '',
			rules: {
				system_crm_port: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				system_jobs_port: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				security_jwt_secret: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				security_override_token: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				security_pkurl: {required: true, validator: notEmpty, trigger: 'blur'},
				// iframe_test_server_ip: { required: true, validator: notEmpty, trigger: 'blur' },    // Change to IP validation
				iframe_dummy_visit_id: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				integration_twillio_sid: {required: true, validator: notEmpty, trigger: 'blur'},
				integration_twillio_token: {required: true, validator: notEmpty, trigger: 'blur'},
				integration_justclick_user: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				// integration_justclick_pass: { required: true, validator: validatePass, trigger: 'blur' },
				integration_justclick_pass: {required: true, validator: notEmpty, trigger: 'blur'},   // delete this one, use the previous one instead
				integration_justclick_key: {required: true, validator: notEmpty, trigger: 'blur'},
				integration_facebook_app_id: {required: true, validator: validateAlphaNumeric, trigger: 'blur'},
				integration_facebook_access_token: {required: true, validator: notEmpty, trigger: 'blur'},
				integration_facebook_proxy_host: {required: true, validator: validateIP, trigger: 'blur'},
				integration_facebook_proxy_port: {required: true, validator: validatePort, trigger: 'blur'},
				// blacklist_blocker_flag: {required: true, validator: notEmpty, trigger: 'blur'},
				// blacklist_auto_clean_flag: {required: true, validator: notEmpty, trigger: 'blur'},
				blacklist_auto_clean_threshold: {required: true, validator: validateIsInteger, trigger: 'blur'},
				blacklist_threshold: {required: true, validator: validateIsInteger, trigger: 'blur'},
				blacklist_ttl: {required: true, validator: validateIsInteger, trigger: 'blur'},
			},
		}
	},
	methods: {
		openBlacklistModal() {
			this.showBlacklistModal = true;
		},
		handleClose(tag) {
			this.form.iframe.domain_list.splice(this.form.iframe.domain_list.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if(!inputValue || inputValue === '') {
				this.inputVisible = false;
				this.inputValue = '';
			} else if (inputValue && (validator.isURL(inputValue) || inputValue.includes('://localhost:'))) {
				if(this.form.iframe.domain_list.includes(inputValue))
					return this.$notify.error({title: 'Validation Error', message: 'Validation failed. The input is already in the list!'})
				else this.form.iframe.domain_list.push(inputValue);
				this.inputVisible = false;
				this.inputValue = '';
			} else {
				this.$notify.error({title: 'Validation Error', message: 'Validation failed. The input is not a valid URL!'})
			}
		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', {filters: '018ec7cd-5b1a-7074-987f-019a837686f2'})
				.then(options => {
					this.options.categories = options.categories.map(e => Object.assign({}, {
						label: e.t + (e.c ? ` (${e.c.substr(0, 32)})` : ''),
						key: e.v,
					}));
				});
		},
		fetchData() {
			this.$apix.sendHttpRequest('GET', 'settings/application')
				.then((res) => {
					// console.log(res);
					this.form = res;
					this.original_form = JSON.parse(JSON.stringify(res));
				})
		},
		ipAdded(event) {
			if (event && !validator.isIP(event[event.length - 1])) this.form.allowed_ips.pop();
		},
		generateNewGuid(field) {
			this.form.security[field] = v4();
		},
		submit() {
			this.$confirm('Are you sure you want to save the changes? This operation cannot be undone!', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			})
				.then(() => {
					this.$refs.form.validate(valid => {
						let loader = this.$loading.show();
						if (!valid) return this.$message.error('Validation failed');
						this.$apix.sendHttpRequest('PUT', 'settings/application', this.form)
						.then(res => {
							this.$notify.success({title: 'API Response', message: 'Application settings updated successfully!'})
							loader.hide();
						})
						.catch(err => {
							console.error(err);
							loader.hide();
						});
					});
				})
				.catch((e) => {
					this.$message({
						type: 'info',
						message: 'Changes discarded'
					});
				});
		},
		resetForm() {
			this.form = JSON.parse(JSON.stringify(this.original_form));
		},
		handleCategoryChange() {
			this.fetchOptions()
		}
	}
}
</script>