import { render, staticRenderFns } from "./dynamic-params.vue?vue&type=template&id=49f33c87&scoped=true&lang=pug&"
import script from "./dynamic-params.vue?vue&type=script&lang=js&"
export * from "./dynamic-params.vue?vue&type=script&lang=js&"
import style0 from "./dynamic-params.vue?vue&type=style&index=0&id=49f33c87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f33c87",
  null
  
)

export default component.exports