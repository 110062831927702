<template lang="pug">
		#dynamic-params-root
				el-form()
						el-row.text-left
								h4 Dynamic Params
								p Dynamic params are kind of templates that are used to be changed to some other values inside our applications. For example, we may have an integration&nbsp;
										| that integrates to a certain API. In some circumstances we may need to change it to something other.
								//p Dynamic params must be used as the following template in the applications:&nbsp;
										span.text-success ||key||
								p You can use dynamic params in 2 different ways by which you will achieve different goals:
								ul
										li
												b ::KEY:: -&nbsp;
												| In case you use this template, the <b class="text-success">key</b> will added to the string you embed it in and saved to the database.&nbsp;
												| Any application that uses dynamic params will replace the template with the relevant <b class="text-danger">value</b> from the list when using that data but the template will remain in the database.
										li
												b ||KEY|| -&nbsp;
												| In case you use this template, the <b class="text-success">key</b> will be replaced by it's <b class="text-danger">value</b> while saving the changes. Once it is saved, the template is gone and it's value replaces it.&nbsp;
												| It might be useful if you need to add something long or repeating to the database.
								p
									i.text-danger Note: You cannot use a key twice. If you do, the last one will override the previous values.
						el-row(:gutter="24")
								el-col(:span="24")
										el-form-item(label="Templates")
												el-button(type="info" icon="el-icon-plus" @click="addRow('dynamic_params')") Add

						.template-options(v-bind:class="{'many-items': dynamic_params.length > 10}")
								el-row.mt-1.mb-1(v-for="(o, i) of dynamic_params" :gutter="6")
										el-col(:xs="24" :sm="8")
												el-input.gap-mobile(v-model="o.key" placeholder="Key")
										el-col(:xs="24" :sm="14")
												el-input.v-desktop(v-model="o.val" placeholder="Value")
												el-input.v-mob.gap-mobile(type="textarea" v-model="o.val" placeholder="Value")
										el-col(:xs="24" :sm="1")
												el-button(icon="el-icon-minus" type="danger" @click="() => dynamic_params.splice(i,1)")

						el-row.mt-3()
								el-col(:span="24" )
										.d-flex.justify-content-center
												el-button(type="success" @click.prevent.native="submit" ) Save
												el-button(type="danger" @click.prevent.native="resetForm" ) Reset

</template>

<style lang="scss" scoped>

.template-options {
		width: 100% !important;
		&.many-items {
				height: 60vh;
				overflow-y: auto;
		}
}

@media only screen and (max-width: 768px) {
		.gap-mobile {
				margin-bottom: 0.4rem;
				margin-top: 0.4rem;
		}
}
</style>

<script>
export default {
		name: 'dynamic-params',
		data() {
				return {
						counter: 0,
						dynamic_params: [],
						original_dynamic_params: []
				}
		},
		mounted() {
				this.fetchData()
		},
		methods: {
				fetchData() {
						this.$apix.sendHttpRequest('GET', 'settings/dynamic-params')
							.then((res) => {
									this.original_dynamic_params = [...res];
									this.dynamic_params = [...res];
									this.counter = res.length+1;
							})
				},
				addRow(type) {
						this[type].push({key: `key_${this.counter}`, val: `value_${this.counter}`});
						this.counter++;
				},
				submit() {
						this.$apix.sendHttpRequest('POST', 'settings/dynamic-params', this.dynamic_params)
							.then(res => {
									this.$notify.success({title: 'API Response', message: 'Dynamic params updated successfully!'})
							});
				},
				resetForm() {
						this.dynamic_params = JSON.parse(JSON.stringify(this.original_dynamic_params));
				}
		}
}
</script>